<template>
  <BaseModal
    :value="dialogState"
    :modal-title="
      isCreateForm
        ? $tc('campaign.createAndEditDialog.createTitle', {
            courseName: activeCourse && activeCourse.name,
          })
        : $tc('campaign.createAndEditDialog.editTitle', {
            campaignName: activeCampaign && activeCampaign.name,
          })
    "
    @close="reset"
  >
    <template #modal>
      <div @submit.prevent="submit()">
        <div>
          <span class="sub-title" v-text="'Personnaliser de la campagne'" />
          <div class="input-fields">
            <BaseTextField
              v-model="form.name"
              autofocus
              :label="$t('campaign.name')"
              :error-messages="nameErrors"
              :has-error="!!nameErrors"
              @keyup.enter="submit()"
            />
            <BaseTextArea
              v-model="form.description"
              :label="$t('campaign.description')"
            />
          </div>
        </div>
        <div>
          <span class="sub-title" v-text="'Paramétre de la campagne'" />
          <div class="input-fields">
            <v-switch
              v-model="form.uniqueAnswer"
              :label="$t('campaign.createAndEditDialog.onceAnswer')"
              inset
            />
            <v-switch
              v-model="form.createFolderForEach"
              :label="$t('campaign.createAndEditDialog.createfolderforEach')"
              inset
            />
            <BaseSelect
              v-if="form.createFolderForEach"
              id="assignedWorkflow"
              v-model="form.workflow"
              :loading="isFetchingWorkflow"
              :items="workflowItems"
              :placeholder="$t('campaign.createAndEditDialog.noWorkflow')"
              item-text="name"
              item-value="id"
              solo
            />
            <BaseSelect
              id="assignedWorkflow"
              v-model="form.assignedCollaborator"
              :loading="isFetchingCollaborators"
              :items="assignedCollaboratorsItems"
              item-text="user.email"
              item-value="user.id"
              solo
            />
          </div>
        </div>
        <div>
          <span class="sub-title" v-text="'Mail d’accompagnement'" />
          <div class="input-fields">
            <BaseMarkdownEditor
              v-model="form.defaultEmailContent"
              :label="$t('campaign.defaultEmailContent')"
              :with-link="false"
            />
          </div>
        </div>
        <div class="relaunch-container">
          <div class="d-flex flex-column title">
            <span class="sub-title" v-text="$t('course.autoReminder')" />
            <span class="text-line" v-text="$t('course.reminderText')" />
          </div>
          <div class="item-container">
            <div class="config-container">
              <v-switch
                v-model="auto_reminder"
                :label="$t('course.reminderLabel')"
                inset
                @change="auto_reminder = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click="submit()">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "CampaignCreateAndEditDialog",
  mixins: [validationMixin],

  data() {
    return {
      form: {
        name: null,
        description: null,
        createFolderForEach: true,
        workflow: null,
        assignedCollaborator: null,
        uniqueAnswer: false,
        defaultEmailContent: "",
      },
      isFetchingCollaborators: false,
      isFetchingWorkflow: true,
      auto_reminder: false,
    };
  },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
      activeCourse: (state) => state.course.course,
      activeCampaign: (state) => state.course.activeCampaign,
      dialogState: (state) =>
        state.course.modals.isCampaignCreateAndEditDialogOpen,
      workflows: (state) => state.workflow.workflows,
    }),
    isCreateForm() {
      return !this.activeCampaign;
    },
    assignedCollaboratorsItems() {
      if (!this.activeOrganization.collaborators) {
        return [];
      }
      return [
        {
          user: {
            id: null,
            email: this.$t("campaign.createAndEditDialog.noCollaborator"),
          },
        },
        ...this.activeOrganization.collaborators,
      ];
    },
    workflowItems() {
      return [
        {
          id: null,
          name: this.$t("campaign.createAndEditDialog.noWorkflow"),
        },
        ...this.workflows,
      ];
    },
    //#region Form errors
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("campaign.createAndEditDialog.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("campaign.createAndEditDialog.nameMaxLength");
      }
      return "";
    },
    //#endregion
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    dialogState(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },
  methods: {
    reset() {
      this.form = {
        name: null,
        description: null,
        createFolderForEach: true,
        assignedCollaborator: null,
        uniqueAnswer: false,
      };
      this.$v.$reset();
      this.$store.commit("course/setCampaignCreateAndEditDialog", false);
    },
    initialize() {
      this.isFetchingCollaborators = true;
      this.isFetchingWorkflow = true;
      this.auto_reminder = this.activeCampaign?.autoReminder;
      this.$store
        .dispatch("workflow/fetchWorkflows", this.activeOrganization.id)
        .then(() => (this.isFetchingWorkflow = false));
      this.$store
        .dispatch(
          "organization/fetchOrganizationCollaborators",
          this.activeOrganization.id
        )
        .finally(() => {
          this.isFetchingCollaborators = false;
          if (!this.isCreateForm) {
            if (this.activeCampaign.assign?.id) {
              this.form.assignedCollaborator = this.activeCampaign.assign?.id;
            }
          }
        });
      if (!this.isCreateForm) {
        this.form.defaultEmailContent = this.activeCampaign.defaultEmailContent;
        this.form.name = this.activeCampaign.name;
        this.form.description = this.activeCampaign.description;
        this.form.createFolderForEach = this.activeCampaign.createFolder;
        this.form.uniqueAnswer = this.activeCampaign.once;
        this.form.workflow = this.activeCampaign.workflow
          ? this.activeCampaign.workflow.id
          : null;
      }
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      if (this.isCreateForm) {
        this.create();
      } else {
        this.edit();
      }
      this.reset();
    },
    create() {
      const payload = {
        name: this.form.name,
        description: this.form.description ? this.form.description : "",
        default_email_content: this.form.defaultEmailContent,
        create_folder: this.form.createFolderForEach,
        workflow: this.form.createFolderForEach ? this.form.workflow : null,
        assign: this.form.assignedCollaborator,
        once: this.form.uniqueAnswer,
        auto_reminder: this.auto_reminder,
      };
      this.$store
        .dispatch("course/createOrganizationCourseCampaign", {
          organizationId: this.activeOrganization.id,
          courseId: this.activeCourse.id,
          payload,
        })
        .then((campaign) => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("campaign.createAndEditDialog.success"),
            type: "SUCCESS",
          });
          this.$router.push({
            name: "campaignDetail",
            params: {
              organizationId: this.$route.params.organizationId,
              courseId: this.$route.params.courseId,
              campaignId: campaign.id,
            },
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
    edit() {
      const payload = {
        name: this.form.name,
        description: this.form.description ? this.form.description : "",
        default_email_content: this.form.defaultEmailContent,
        create_folder: this.form.createFolderForEach,
        workflow: this.form.createFolderForEach ? this.form.workflow : null,
        assign: this.form.assignedCollaborator,
        once: this.form.uniqueAnswer,
        auto_reminder: this.auto_reminder,
      };
      this.$store
        .dispatch("course/updateOrganizationCourseCampaign", {
          organizationId: this.activeOrganization.id,
          courseId: this.activeCourse.id,
          campaignId: this.activeCampaign.id,
          payload,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("campaign.createAndEditDialog.success"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 8px 0px 6px 0px;
}
.input_group {
  .label {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 1em;
    color: #707080;
  }
  ::v-deep {
    .v-input__control .v-input__slot {
      & > fieldset {
        border: 1px solid #b6bdff;
      }
    }
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.input-fields {
  padding: 24px 24px 0 24px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}


.relaunch-container {
  padding-inline: 24px;
  .title {
    margin-top: 24px;
    gap: 10px;
    .sub-title {
      font-family: "Inter";
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #353542;
    }

    .text-line {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
    }
  }
  .item-container {
    margin-top: 24px;

    .config-container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 24px;

      .config {
        width: 100%;
        padding: 16px;
        ::v-deep.label {
          font-family: Inter;
          font-size: 14px !important;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
        }
      }
    }
  }
}
</style>
