<template>
  <div>
    <v-checkbox
      v-model="localValue"
      class="mr-2"
      :name="name"
      :label="label"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #label>
        <slot name="label" />
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeCheckbox",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localValue: false,
    };
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      this.$emit("updateValue", !!newVal);
    },
  },
};
</script>
