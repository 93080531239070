<template>
  <div>
    <component :is="layout" />
  </div>
</template>

<script>
import BackofficeLayout from "@/core/Layouts/BackofficeLayout.vue";
import FrontofficeLayout from "@/core/Layouts/FrontofficeLayout.vue";
import LoginSignupLayout from "@/core/Layouts/LoginSignupLayout.vue";
import SimpleLayout from "@/core/Layouts/SimpleLayout.vue";
import { mapState } from "vuex";
import * as Sentry from "@sentry/vue";

export const LayoutTemplates = {
  FrontOffice: 1,
  BackOffice: 2,
  Welcome: 3,
  LoginSignup: 4,
  SimpleLayout: 5,
};

export default {
  name: "App",
  props: {
    staticFiles: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({ user: (state) => state.account.user }),
    layout() {
      switch (this.getLayout()) {
        case LayoutTemplates.BackOffice:
          return BackofficeLayout;
        case LayoutTemplates.Welcome:
          break;
        case LayoutTemplates.LoginSignup:
          return LoginSignupLayout;
        case LayoutTemplates.SimpleLayout:
          return SimpleLayout;
        case LayoutTemplates.FrontOffice:
      }
      return FrontofficeLayout;
    },
  },
  watch: {
    user: {
      handler() {
        // set sentry identifiers when an error occured
        Sentry.setUser({
          email: this.user?.email,
          username: `${this.user?.firstName} ${this.user?.lastName}`,
          id: this.user?.id,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$setStatic(JSON.parse(this.staticFiles));
    this.initLanguage();
  },

  methods: {
    getLayout() {
      const path = this.$route.fullPath;
      if (
        path.indexOf("/auth/login") != -1 ||
        path.indexOf("/auth/signup") != -1 ||
        path.indexOf("/auth/password/reset") != -1 ||
        path.indexOf("/auth/password/reset/done") != -1 ||
        path.indexOf("/auth/password/reset/confirm") != -1 ||
        path.indexOf("/auth/password/reset/complete") != -1 ||
        path.indexOf("/500") != -1 ||
        path.indexOf("/404") != -1
      ) {
        return LayoutTemplates.LoginSignup;
      }
      if (
        path.indexOf("/space-manager/") != -1 ||
        path.indexOf("/cosmoform/") != -1 ||
        path.indexOf("/client-full-data/") != -1
      ) {
        return LayoutTemplates.SimpleLayout;
      }
      if (
        path.indexOf("/organization") == 0 ||
        path.indexOf("/dashboard") == 0 ||
        path.indexOf("/auth") == 0
      ) {
        return LayoutTemplates.BackOffice;
      }

      return LayoutTemplates.FrontOffice;
    },
    initLanguage() {
      this.$i18n.locale = navigator.language.includes("en")
        ? "en"
        : process.env.VUE_APP_I18N_LOCALE;
    },
  },
};
</script>
<style>
html {
  height: 100vh;
  overflow: auto;
}
.selected-tab {
  background: var(--v-walletPrimary-lighten5) !important;
}
</style>
