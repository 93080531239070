<template>
  <TheBaseApp v-if="isAppReady">
    <TheDrawer />
    <v-main>
      <TheAppBar />
      <div class="page_wrapper">
        <router-view />
      </div>
    </v-main>
    <TheSnackbar />
  </TheBaseApp>
</template>

<script>
import TheBaseApp from "../Components/TheBaseApp";
import TheDrawer from "../Components/TheDrawer";
import TheAppBar from "../Components/TheAppBar";
import TheSnackbar from "../Components/TheSnackbar";
import Oauth from "../Models/Oauth";
import { setBearerToken } from "../Api/cosmoAxiosInstance";
import { mapState } from "vuex";

export default {
  name: "BackofficeLayout",
  components: {
    TheBaseApp,
    TheDrawer,
    TheAppBar,
    TheSnackbar,
  },

  data() {
    return {
      isAppReady: false,
    };
  },
  computed: {
    ...mapState({ hasLoggedNormaly: (state) => state.core.hasLoggedNormaly }),
  },
  created() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },

  methods: {
    reset() {
      this.$store.commit("organization/setActiveOrganization", null);
      this.$store.commit("organization/setOrganizations", []);
    },
    async initialize() {
      this.isAppReady = false;
      if (Oauth.localStorage && !this.hasLoggedNormaly) {
        // if oauth exist we refresh the token.
        const oauth = Oauth.localStorage;
        try {
          setBearerToken(oauth.accessToken);
        } catch (error) {
          // if its not valid we redirect to login
          this.$store.dispatch("core/resetApp");
          return this.$router.push({
            name: "Login",
            params: { next: this.$route.fullPath },
          });
        }
      }
      if (this.user == null) {
        await this.$store.dispatch("account/fetchUser");
      }

      await this.$store.dispatch("organization/fetchOrganizations");
      this.isAppReady = true;
    },
  },
};
</script>

<style lang="scss" src="./../Assets/scss/typography.scss"></style>

<style lang="scss" scoped>
.page_wrapper {
  height: calc(100vh - 72px);
  overflow: auto;
  margin: 8px;
  padding: 32px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #eeeef7;
  }

  &::-webkit-scrollbar-thumb {
    background: #b6bdff;
    border-radius: 3px;
  }
}
</style>

<style>
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.top {
  top: 0;
}
.right {
  right: 0;
}
.bottom {
  bottom: 0;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
</style>
