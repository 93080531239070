<template>
  <div>
    <BaseSnackbar
      :color="
        snackbar.type === 'SUCCESS'
          ? 'success lighten-5'
          : 'secondary lighten-5'
      "
      right
      :timeout="snackbar.timeout"
      :value="snackbar.active"
      @input="onClick"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <BaseIcon
            v-if="snackbar.type === 'SUCCESS'"
            icon="$mdiCheck"
            color="success"
          />
          <BaseIcon v-else icon="$mdiAlertDecagramOutline" color="secondary" />
          <BaseDescription class="ml-4" v-text="snackbar.message" />
        </div>
        <BaseButtonIcon
          v-if="snackbar.needClose"
          icon="$mdiClose"
          color="grey"
          @click="onClick"
        />
      </div>
    </BaseSnackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheSnackbar",
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar,
    }),
  },
  methods: {
    onClick() {
      this.$store.dispatch("snackbar/reset");
    },
  },
};
</script>
