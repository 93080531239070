<template>
  <div cl>
    <BaseModal
      :width="850"
      :value="isSendMailDialogOpen"
      :modal-title="$t('utils.emailAction.sendMail')"
      @keydown.esc.prevent="reset()"
      @click:outside="reset()"
      @close="reset()"
    >
      <template #modal>
        <div class="container">
          <span class="sub-title" v-text="`Destinataires`" />
          <div class="destinataire">
            <span class="label" v-text="`A`" />
            <v-combobox
              v-model="form.to"
              :items="formatedSignatories"
              :filter="filterSignatory"
              :search-input.sync="queryToText"
              class="email_input elevation-0"
              chips
              item-value="email"
              clearable
              multiple
              outlined
              dense
              :append-icon="!isCc ? '$emailCcIcon' : ''"
              @click:append="isCc = !isCc"
              @change="(value) => insertValue(value, 'to')"
            >
              <template #selection="{ attrs, item, select, selected }">
                <v-chip
                  class="mail-chips"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove('to', item)"
                >
                  {{ item }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <div class="signatory_item">
                  <BaseIcon class="icon" :icon="item.icon" />
                  <div class="email" v-text="item.email" />
                  <div
                    v-if="item.lastName || item.firstname"
                    class="separator"
                    v-text="'-'"
                  />
                  <div
                    v-if="item.lastName"
                    class="name"
                    v-text="item.lastName"
                  />
                  <div
                    v-if="item.firstName"
                    class="firstname"
                    v-text="item.firstName"
                  />
                </div>
              </template>
            </v-combobox>
            <span v-if="isCc" class="label" v-text="`Cc`" />
            <v-combobox
              v-if="isCc"
              v-model="form.cc"
              :items="formatedSignatories"
              :filter="filterSignatory"
              :search-input.sync="queryCCText"
              class="email_input elevation-0"
              chips
              clearable
              multiple
              outlined
              item-value="email"
              dense
              append-icon=""
              @change="(value) => insertValue(value, 'cc')"
            >
              <template #selection="{ attrs, item, select, selected }">
                <v-chip
                  class="mail-chips"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove('cc', item)"
                >
                  {{ item }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <div class="signatory_item">
                  <BaseIcon class="icon" :icon="item.icon" />
                  <div class="email" v-text="item.email" />
                  <div
                    v-if="item.lastName || item.firstname"
                    class="separator"
                    v-text="'-'"
                  />
                  <div
                    v-if="item.lastName"
                    class="name"
                    v-text="item.lastName"
                  />
                  <div
                    v-if="item.firstName"
                    class="firstname"
                    v-text="item.firstName"
                  />
                </div>
              </template>
            </v-combobox>
          </div>
          <span class="sub-title" v-text="`Email`" />
          <div class="email-container">
            <span class="label" v-text="`Objet`" />
            <BaseTextField
              v-model="form.subject"
              autofocus
              dense
              name="name"
              class="offer-item"
            />
            <span class="label" v-text="`Message`" />
            <BaseMarkdownEditor
              v-model="form.content"
              name="Message"
              :with-link="false"
            />
          </div>
          <span class="sub-title" v-text="`Fichiers`" />
          <div class="file-container">
            <folder-offer-file-drag
              class="file-drag"
              :class="error ? 'text-error' : ''"
              :offer-files="files"
              is-multiple
              @file-droped="fileInserted"
              @delete-offer-file="removeFile"
              @file-name-changed="updateFileName"
            />
            <p v-if="error" class="text-error">Fichier manquant</p>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseButton text type="secondary" class="mx-2 ml-auto" @click="reset()">
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton
          :loading="isSubmitting"
          color="primary"
          type="primary"
          @click="sendMail"
        >
          {{ $t("utils.send") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FolderOfferFileDrag from "../Components/FolderOffer/FolderOfferFileDrag.vue";
import FolderAPI from "@/modules/Folder/Services/folder.api";
import { fileURLToFile, getDataBlob } from "@/core/Utils/file.utils";
import { Signatory, SIGNATORY_TYPE } from "@/core/Models/Signatory";
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { removeDuplicates } from "@/core/Utils/array.utils";

export default {
  name: "FolderSendMailDialog",
  components: { FolderOfferFileDrag },
  data() {
    return {
      form: {
        to: [],
        cc: [],
        content: null,
        subject: null,
      },
      files: [],
      templateId: null,
      isCc: false,
      isSubmitting: false,
      error: false,
      suggestedSignatories: [],
      queryToText: "",
      queryCCText: "",
    };
  },
  computed: {
    ...mapState({
      isSendMailDialogOpen: (state) => state.folder.modals.isSendMailDialogOpen,
      templateMail: (state) => state.folder.templateMail,
      activeFolder: (state) => state.folder.activeFolder,
    }),
    formatedSignatories() {
      let tmp = this.suggestedSignatories;
      if (this.activeFolder?.emails?.length)
        tmp = [
          ...this.activeFolder.emails.map(
            (s) =>
              new Signatory({
                ...s,
                firstName: s.firstname,
                lastName: s.lastname,
                phoneNumber: s.phone,
                type: SIGNATORY_TYPE.FOLDER_SIGNATORY,
              })
          ),
          ...tmp,
        ];
      return removeDuplicates(tmp, "email");
    },
  },
  watch: {
    isSendMailDialogOpen: {
      handler() {
        this.fetchSignatories();
        if (this.templateMail) {
          this.inititialize();
        }
      },
    },
  },

  methods: {
    insertValue(item, key) {
      if (key == "to") this.queryToText = "";
      else this.queryToText = "";
      let newItem = [...item].pop();
      this.form[key].pop();
      if (newItem.email) {
        return this.form[key].push(newItem.email);
      }
      this.form[key].push(newItem);
    },
    async fetchSignatories() {
      this.fetchingsSuggestedSignatories = true;
      try {
        const tmpSignatories = await Promise.all([
          await DashboardAPI.getUserSignatories(),
          await DashboardAPI.getOrganizationSignatories({
            organizationId: this.$route.params.organizationId,
          }),
        ]);
        this.suggestedSignatories = [
          ...tmpSignatories[0].data.results.map(
            (s) =>
              new Signatory({ ...s, type: SIGNATORY_TYPE.PERSONAL_SIGNATORY })
          ),
          ...tmpSignatories[1].data.results.map(
            (s) =>
              new Signatory({
                ...s,
                type: SIGNATORY_TYPE.ORGANIZATION_SIGNATORY,
              })
          ),
        ];
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingsSuggestedSignatories = false;
    },
    reset() {
      this.$store.commit("folder/setSendMailDialogOpen", false);
      this.form = {};
      this.error = false;
    },

    async inititialize() {
      const { to, content, subject, cc, datatypeFiles } = this.templateMail;
      this.form.to = to;
      this.form.content = content;
      this.form.subject = subject;
      let filesPromises = [];
      if (!datatypeFiles.files.length) this.error = true;
      datatypeFiles.files.forEach((file) => {
        return filesPromises.push(
          getDataBlob(file.url ? file.url : file.content)
        );
      });
      const files = [];
      Promise.all(filesPromises).then(async (blobFiles) => {
        for (const [index, blobFile] of blobFiles.entries())
          files.push(
            await fileURLToFile(blobFile, datatypeFiles.files[index].name)
          );

        this.files = [...files];
      });

      if (cc) {
        this.isCc = true;
        this.form.cc = cc;
      }
    },

    async sendMail() {
      if (!this.form.to?.length) return;
      const payload = {
        template: this.templateMail.id,
        to: this.form.to.join(";"),
        cc: this.form.cc.join(";"),
        content: this.form.content,
        subject: this.form.subject,
        force: true,
      };
      const formData = new FormData();
      for (const key in payload) {
        if (payload[key]) formData.append(key, payload[key]);
      }
      this.files.forEach((file) => {
        if (file) formData.append("set_files", file);
      });

      const data = {
        folderId: parseInt(this.$route.params.folderId),
        statusId: this.activeFolder.activeStatus,
        organizationId: parseInt(this.$route.params.organizationId),
        actionId: this.templateMail.actionId,
        payload: formData,
      };

      try {
        const res = await FolderAPI.sendMail(data);
        res &&
          this.$store.commit("folder/updateFolderRecoordsOffersCosmoform", {
            ...res.data,
            type: "EMAIL",
          });
        this.$store.dispatch("snackbar/active", {
          message: "Email envoyé",
          type: "SUCCESS",
        });
        this.reset();
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: "Echec d'envoi",
          type: "ERROR",
        });
        this.reset();
      }
    },
    async fileInserted({ value }) {
      let fileList = this.files.length ? [...this.files] : [];
      for (const file of value) {
        fileList.push(file);
      }
      this.files = [...fileList];
      this.error = false;
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    remove(type, item) {
      this.form[type].splice(this.form[type].indexOf(item), 1);
    },

    async updateFileName({ value, index }) {
      const newFile = new File([this.files[index]], `${value}`, {
        type: this.files[index].type,
      });
      this.files.splice(index, 1, newFile);
    },
    filterSignatory(item, queryText) {
      if (!queryText) return true;
      return (
        item.email &&
        item.email.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: none;
  .destinataire {
    padding: 0px 16px 0px 16px;
    .label {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .email-container {
    padding: 0px 16px 0px 16px;
    .label {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .file-container {
    padding: 0px 16px 0px 16px;
    .file-drag-container {
      &.text-error {
        border: 1px dashed #ff5267 !important;
        .file-drag-repo {
          border: 1px dashed #ff5267 !important;
        }
      }
    }
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}

::v-deep .email_input .v-input__icon--append {
  display: none;
}

.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none !important;
}

::v-deep .v-input__slot .v-select__selections {
  margin: 0 !important;
}
.mail-chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}

.text-error {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ff5267;
  margin-top: 8px;
}
.signatory_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  color: #323338;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  .separator {
    margin: 0;
  }
  .icon {
    ::v-deep {
      .v-icon__svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .name {
    text-transform: uppercase;
  }
  .firstname {
    text-transform: capitalize;
  }
}
</style>
