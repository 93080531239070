<template>
  <div>
    <BaseModal
      :value="dialogState"
      :modal-title="$t('folder.assignOwnerForm.title')"
      is-complex
      width="900"
      @close="reset"
    >
      <template #modal>
        <BaseSelect
          v-model="selected"
          :loading="isFetchingContacts"
          :menu-props="{ offsetY: true }"
          :items="contacts"
          item-text="email"
          item-value="id"
          solo
        />
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="confirmAndSubmit()">
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
    <FolderConfirmChangeContactDialog
      :dialog-state="openConfirmDialog"
      :email="email"
      @close="openConfirmDialog = false"
      @validate="submit(true)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientAPI from "@/modules/Client/Services/client.api";
import FolderConfirmChangeContactDialog from "./FolderConfirmChangeContactDialog.vue";

export default {
  name: "FolderChangeOwnerDialog",
  components: {
    FolderConfirmChangeContactDialog,
  },
  data: function () {
    return {
      selected: -1,
      folderId: null,
      contacts: [],
      isFetchingContacts: false,
      openConfirmDialog: false,
      email: "",
    };
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isFolderChangeClient,
      activeFolder: (state) => state.folder.activeFolder,
    }),
  },

  watch: {
    async dialogState(val) {
      if (val) {
        await this.fetchOrganizationContacts();
      }
      if (val && this.selected == -1) {
        this.selected = this.activeFolder?.client?.id;
      }
    },
  },
  methods: {
    reset() {
      this.$store.commit("folder/setFolderChangeClient", false);
    },
    confirmAndSubmit() {
      if (this.activeFolder.client && !this.activeFolder.client.isTemporary) {
        this.email = this.contacts.find((c) => c.id == this.selected)?.email;
        this.openConfirmDialog = true;
        return;
      }
      this.submit();
    },
    async submit(featchWorkflow) {
      this.openConfirmDialog = false;
      let organizationId = this.$route.params.organizationId;
      let folderId = this.$route.params.folderId;
      let payload = { client: this.selected };
      if (this.selected !== -1)
        try {
          await this.$store.dispatch("folder/updateFolder", {
            organizationId,
            folderId,
            payload,
          });
          if (featchWorkflow) await this.fetchWorkflow();
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.success"),
            type: "SUCCESS",
          });
        } catch {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("folder.ownerAction.error"),
            type: "ERROR",
          });
        }
      this.reset();
    },
    async fetchOrganizationContacts() {
      this.isFetchingContacts = true;
      this.contacts = [];
      if (!isNaN(this.$route.params.organizationId)) {
        let page = 1;
        while (page) {
          const res = await ClientAPI.getOrganizationClients(
            this.$route.params.organizationId,
            page
          );
          this.contacts = [...this.contacts, ...res.data.results];
          page = res.data.next;
        }
      }
      this.isFetchingContacts = false;
    },
    async fetchWorkflow() {
      if (!this.activeFolder?.workflow) return;
      this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
      await this.$store.dispatch("workflow/fetchActiveWorkflow", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.activeFolder?.workflow,
      });
    },
  },
};
</script>
<style scoped>
.select-width {
  width: 100%;
}
</style>
