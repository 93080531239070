<template>
  <div>
    <BaseModal
      :value="dialogState"
      modal-title="Envoyer les champs"
      @close="reset"
    >
      <template #modal>
        <form @submit.prevent>
          <div>
            <span
              class="sub-title"
              v-text="$t('cosmoform.dialog.concernedFields')"
            />
            <div class="input-fields">
              <div class="cosmo-fields-container">
                <div
                  v-for="(group, i) in groupedQuestionsByCategory"
                  :key="`g-${group.id + 1}${i}`"
                  class="my-2"
                >
                  <div v-if="group.id" class="question_category">
                    {{ group.name }}
                  </div>
                  <div class="question_list">
                    <div
                      v-for="question in group.questions"
                      :key="`gq-${question.id + 1}${i}`"
                      class="question_item"
                    >
                      <div class="name">
                        {{ question.name }}
                      </div>

                      <div class="ml-2">
                        <BaseIcon
                          :color="question.readOnly ? 'primary' : '#CFCFDC'"
                          small
                          icon="$mdiPencilOffOutline"
                        />
                        <BaseButtonIcon
                          icon="$mdiClose"
                          color="primary"
                          @click="unSelectQuestion(question.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span class="sub-title" v-text="'Formulaire'" />
            <div class="input-fields">
              <div class="cosmoform_template_form">
                <span class="label" v-text="'Template'" />

                <BaseComboBox
                  :value="form.title"
                  :items="cosmoformTemplates"
                  :loading="
                    isFetchingCosmoformTemplates ||
                    isFetchingCosmoformTemplateDetail
                  "
                  solo
                  outlined
                  item-text="title"
                  item-value="id"
                  need-selection
                  :disabled="isDisabled"
                  @change="setFormValueByTemplate"
                >
                  <template #selection="{ item }">
                    <span>{{ item }}</span>
                    <span
                      v-if="item === defaultCosmoformTitle"
                      class="select-default ml-3"
                      v-text="'(Par défaut)'"
                    />
                  </template>
                </BaseComboBox>
                <BaseMarkdownEditor
                  v-model="form.description"
                  label="Description"
                />
              </div>
            </div>
          </div>
          <div>
            <span class="sub-title" v-text="'Message et destinataires'" />
            <div class="input-fields last-input">
              <BaseComboBox
                v-model="form.email"
                :items="formatedSignatories"
                :filter="filterSignatory"
                :search-input.sync="queryText"
                :label="`${$t('cosmoform.dialog.email')} *`"
                :error-messages="emailErrors"
                :has-error="emailErrors !== ''"
                chips
                solo
                outlined
                need-selection
                need-item
                item-value="email"
                :disabled="emailFormDisabled"
                @change="emailChanged"
              >
                <template #selection="{ attrs, item, selected }">
                  <v-chip
                    v-bind="attrs"
                    class="mail-chips"
                    :input-value="selected"
                    :close="!emailFormDisabled"
                    @click:close="() => (form.email = null)"
                  >
                    <Strong> {{ item }} </Strong>
                  </v-chip>
                </template>
                <template #item="{ item }">
                  <div class="signatory_item">
                    <BaseIcon class="icon" :icon="item.icon" />
                    <div class="email" v-text="item.email" />
                    <div
                      v-if="item.lastName || item.firstname"
                      class="separator"
                      v-text="'-'"
                    />
                    <div
                      v-if="item.lastName"
                      class="name"
                      v-text="item.lastName"
                    />
                    <div
                      v-if="item.firstName"
                      class="firstname"
                      v-text="item.firstName"
                    />
                  </div>
                </template>
              </BaseComboBox>
              <div class="input_group mb-4">
                <div
                  class="label"
                  v-text="$t('cosmoform.dialog.emailContent')"
                />
                <BaseMarkdownEditor
                  v-model="form.emailContent"
                  :with-link="false"
                />
              </div>
              <v-switch
                v-model="form.public"
                class="checkbox"
                inset
                hide-details
                :false-value="true"
                :true-value="false"
                :label="$t('cosmoform.dialog.accountRequired')"
              />
            </div>
          </div>
        </form>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton
          :disabled="!isFormValid"
          :loading="isSubmitting"
          type="primary"
          @click.prevent="onSave"
        >
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
    <folder-confirm-assign-dialog
      :is-open="openConfirmModal"
      :confirm-email="form.email"
      @on-validate="onSave(true)"
      @close="
        () => {
          openConfirmModal = false;
          isSubmitting = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import {
  createOrganizationFolderCosmoform,
  getOrganizationCosmoformTemplate,
  getOrganizationCosmoformTemplateDetail,
} from "./../../Admin/Services/workflow.service";
import BaseMarkdownEditor from "../../../core/Components/UI/Markdown/BaseMarkdownEditor.vue";
import FolderConfirmAssignDialog from "./FolderConfirmAssignDialog.vue";
import { Signatory, SIGNATORY_TYPE } from "@/core/Models/Signatory";
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";
import { removeDuplicates } from "@/core/Utils/array.utils";

export default {
  name: "FolderWorkflowSendCosmoFormDialog",
  components: { BaseMarkdownEditor, FolderConfirmAssignDialog },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      cosmoformTemplateId: null,
      cosmoformTemplates: [],
      isFetchingCosmoformTemplates: false,
      isFetchingCosmoformTemplateDetail: false,
      isDisabled: false,
      openConfirmModal: false,
      emailFormDisabled: false,
      suggestedSignatories: [],
      form: {
        public: true,
        email: "",
        emailContent: "",
        description: "",
        title: "",
      },
      queryText: "",
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      title: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
      activeOrganization: (state) => state.organization.activeOrganization,
      dialogState: (state) => state.folder.modals.isSendCosmoFormOpen,
      activeQuestions: (state) => state.folder.activeQuestions,
      activeStatus: (state) => state.workflow.activeStatus,
      workflowId: (state) => state.folder.activeFolder.workflow.id,
      offersCosmoformsRecords: (state) => state.folder.offersRecordsCosmoforms,
      defaultCosmoformTemplate: (state) => state.folder.cosmoformTemplateId,
      isCosmoformAction: (state) => state.folder.isCosmoformAction,
      activeControl: (state) => state.folder.activeControl,
    }),
    isFormValid() {
      return this.activeQuestions && this.activeQuestions.length > 0;
    },
    hasClient() {
      return !this.activeFolder?.client?.isTemporary;
    },
    defaultCosmoformTitle() {
      let title = "Formulaire";
      if (!this.activeOrganization) return title;
      return `${title} "${this.activeOrganization.name}"`;
    },
    emailErrors() {
      if (!this.$v.form.email.$dirty) {
        return "";
      }
      if (!this.$v.form.email.required) {
        return this.$t("cosmoform.dialog.mailRequired");
      }
      if (!this.$v.form.email.email) {
        return this.$t("cosmoform.dialog.invalidMail");
      }
      return "";
    },
    titleErrors() {
      if (!this.$v.form.title.$dirty) {
        return "";
      }
      if (!this.$v.form.title.required) {
        return this.$t("cosmoform.dialog.titleRequired");
      }
      return "";
    },
    groupedQuestionsByCategory() {
      if (this.activeControl)
        return [
          {
            id: null,
            name: this.$t("category.uncategorized"),
            questions: this.activeQuestions,
          },
        ];
      if (
        !this.activeStatus ||
        !this.activeQuestions ||
        this.activeQuestions.length === 0
      )
        return [];
      let groupedQuestions = this.activeStatus.categories
        .map((sc) => ({
          ...sc,
          statusId: this.activeStatus.id,
          isFirst:
            sc.order ===
            Math.min(
              ...this.activeStatus.categories.map((tmpSC) => tmpSC.order)
            ),
          isLast:
            sc.order ===
            Math.max(
              ...this.activeStatus.categories.map((tmpSC) => tmpSC.order)
            ),
          questions: this.activeQuestions
            .filter((q) => q.category?.id === sc.id)
            .sort((a, b) => a.order - b.order),
        }))
        .filter((sc) => sc.questions.length)
        .map((sc) => ({
          ...sc,
        }))
        .sort((a, b) => a.order - b.order);
      const withoutCategoryGroup = {
        id: null,
        name: this.$t("category.uncategorized"),
        statusId: this.activeStatus.id,
        questions: this.activeQuestions.filter((q) => !q.category),
      };
      groupedQuestions.unshift(withoutCategoryGroup);
      return groupedQuestions;
    },
    selectedCosmoformTemplateTitle() {
      if (!this.cosmoformTemplateId) return "-";
      return this.cosmoformTemplates.find(
        (ct) => ct.id == this.cosmoformTemplateId
      ).title;
    },
    formatedSignatories() {
      let tmp = this.suggestedSignatories;
      if (this.activeFolder?.emails?.length)
        tmp = [
          ...this.activeFolder.emails.map(
            (s) =>
              new Signatory({
                ...s,
                firstName: s.firstname,
                lastName: s.lastname,
                phoneNumber: s.phone,
                type: SIGNATORY_TYPE.FOLDER_SIGNATORY,
              })
          ),
          ...tmp,
        ];
      return removeDuplicates(tmp, "email");
    },
  },
  watch: {
    dialogState(value) {
      if (value) {
        this.initialize();
      }
    },
    "form.public": {
      handler() {
        if (!this.form.public && this.hasClient) {
          this.emailFormDisabled = true;
          return (this.form.email = this.activeFolder?.client?.email);
        }
        return (this.emailFormDisabled = false);
      },
    },
  },

  methods: {
    emailChanged(value) {
      this.form.email = value?.email ? value.email : value;
      this.$v.form.email.$touch();
      this.queryText = "";
    },
    async fetchSignatories() {
      this.fetchingsSuggestedSignatories = true;
      try {
        const tmpSignatories = await Promise.all([
          await DashboardAPI.getUserSignatories(),
          await DashboardAPI.getOrganizationSignatories({
            organizationId: this.$route.params.organizationId,
          }),
        ]);
        this.suggestedSignatories = [
          ...tmpSignatories[0].data.results.map(
            (s) =>
              new Signatory({ ...s, type: SIGNATORY_TYPE.PERSONAL_SIGNATORY })
          ),
          ...tmpSignatories[1].data.results.map(
            (s) =>
              new Signatory({
                ...s,
                type: SIGNATORY_TYPE.ORGANIZATION_SIGNATORY,
              })
          ),
        ];
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingsSuggestedSignatories = false;
    },
    reset() {
      this.cosmoformTemplateId = null;
      this.isDisabled = false;
      this.form = {
        email: null,
        public: true,
        emailContent: null,
        title: null,
        description: null,
      };
      this.$v.form.$reset();
      this.$store.commit("folder/SET_MODAL_SEND_COSMO_FORM", false);
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", []);
      this.$store.commit("folder/SET_ACTIVE_CONTROL", null);
      this.$store.commit("folder/SET_COSMO_FORM_TEMPLATE_ID", null);
      this.openConfirmModal = false;
    },

    async initialize() {
      this.isFetchingCosmoformTemplates = true;
      this.cosmoformTemplates = await getOrganizationCosmoformTemplate({
        organizationId: this.$route.params.organizationId,
      });
      await this.fetchSignatories();
      this.isFetchingCosmoformTemplates = false;
      this.form.title = this.defaultCosmoformTitle;
      this.form.description = this.$t("cosmoform.instruction");
      this.form.emailContent = this.$t("cosmoform.defaultMail");
      if (this.defaultCosmoformTemplate) {
        this.setFormValueByTemplate(
          this.cosmoformTemplates.find(
            (item) => item.id === this.defaultCosmoformTemplate
          )
        );
      }
    },
    async setFormValueByTemplate(value) {
      // if input simple
      if (!value?.id) {
        return (this.form.title = value);
      }
      this.form.title = value.title;
      const cosmoformTemplate = this.cosmoformTemplates.find(
        (ct) => ct.id == value.id
      );
      if (!cosmoformTemplate.description) {
        this.isFetchingCosmoformTemplateDetail = true;
        cosmoformTemplate.setDetail(
          await getOrganizationCosmoformTemplateDetail({
            organizationId: this.$route.params.organizationId,
            cosmoformTemplateId: value.id,
          })
        );
        this.isFetchingCosmoformTemplateDetail = false;
      }

      this.form.public = !cosmoformTemplate.accountRequired;
      this.form.description = cosmoformTemplate.description;
      this.isCosmoformAction && (this.isDisabled = true);
      this.form.emailContent = cosmoformTemplate.emailContent;
    },
    unSelectQuestion(questionId) {
      this.$store.commit(
        "folder/SET_ACTIVE_QUESTIONS",
        this.activeQuestions.filter((q) => q.id !== questionId)
      );
    },
    async onSave(isDirectClient) {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmitting = true;
      if (!this.hasClient && !this.openConfirmModal && !this.form.public) {
        return (this.openConfirmModal = true);
      }
      try {
        const emailContentWithLineBreak = this.form.emailContent.replaceAll(
          "\n",
          "</p></br><p>"
        );
        const payload = {
          is_public: this.form.public,
          email: this.form.email,
          question: this.activeQuestions.map((q) => q.id),
          email_content: emailContentWithLineBreak,
          title: this.form.title,
          description: this.form.description,
        };
        await createOrganizationFolderCosmoform({
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
          payload: payload,
        });
        const newClient = await this.$store.dispatch(
          "folder/fetchClientOwnfolder",
          {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
          }
        );
        await this.$store.dispatch("folder/getOrganizationOffersRecordFolder", {
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
        });
        if (isDirectClient) {
          this.$store.commit("folder/SET_ACTIVE_FOLDER", {
            ...this.activeFolder,
            client: newClient[0],
          });
        }
        this.$store.commit("folder/SET_ACTIVE_QUESTIONS", []);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("cosmoform.dialog.requestSent"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.$store.dispatch("folder/fetchActiveFolderCosmoformDetails", {
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
      });
      this.isSubmitting = false;
      this.reset();
    },
    filterSignatory(item, queryText) {
      if (!queryText) return true;
      return (
        item.email &&
        item.email.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px 16px 0 16px;
}

.question_category {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #707080;
}
.question_list {
  display: flex;
  flex-wrap: wrap;

  .question_item {
    border: 1px solid #2536cc;
    margin: 8px;
    height: 33px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .name {
      margin: 0 5px;
      font-size: 16px;
      line-height: 18px;
      color: #2536cc;
      max-height: 36px;
      text-align: center;
      overflow: hidden;
    }
  }
}
.field_label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #353542;
}
.modal_actions {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}
.cosmoform_template_container {
  padding: 0;
  &::v-deep {
    .v-expansion-panel {
      border-radius: 10px !important;
      border: 2px solid #b6bdff;
      &::before {
        box-shadow: none;
      }
    }
  }
  .cosmoform_template_header {
    padding: 8px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    .header_title {
      color: #707080;
      margin-bottom: 8px;
    }
    .header_subtitle {
      color: #2536cc;
    }
  }
  .progess_section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 16px;
  }
  .cosmoform_template_form {
    .input_group {
      .label {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 1em;
        color: #707080;
      }
      ::v-deep {
        .v-input__control .v-input__slot {
          & > fieldset {
            border: 1px solid #b6bdff;
          }
        }
      }
    }
  }
}

.checkbox {
  margin: 0 0 16px 0 !important;
  .checkbox_label {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 8px;
    }
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.mail-chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}

.input-fields {
  padding: 24px;
}
.cosmo-fields-container {
  border: 2px solid #cfcfdc;
  border-radius: 5px;
  padding: 8px;
}
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #707080;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.select-default {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #707080;
}
.last-input {
  padding-bottom: 0px !important;
}
.signatory_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  color: #323338;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  .separator {
    margin: 0;
  }
  .icon {
    ::v-deep {
      .v-icon__svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .name {
    text-transform: uppercase;
  }
  .firstname {
    text-transform: capitalize;
  }
}
</style>
