export default {
  namespaced: true,
  state: {
    active: false,
    type: "",
    message: "",
    needClose: true,
    timeout: 4000, // In Milliseconds
  },
  mutations: {
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_TYPE(state, payload) {
      state.type = payload;
    },
    SET_ACTIVE(state, payload) {
      state.active = payload;
    },
    SET_NEED_CLOSE(state, payload) {
      state.needClose = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("SET_ACTIVE", false);
    },
    active({ commit }, { message, type, needClose = true }) {
      commit("SET_MESSAGE", message);
      commit("SET_TYPE", type);
      commit("SET_NEED_CLOSE", needClose);
      commit("SET_ACTIVE", true);
    },
  },
};
