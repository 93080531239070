var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseDivider',{staticClass:"mt-8 mb-4",attrs:{"grey":""}}),_c('div',{staticClass:"d-flex justify-center my-4"},[(_vm.$route.fullPath.indexOf('/wallet') != -1)?_c('router-link',{staticClass:"link_signup",attrs:{"to":{
        name: 'WalletRegister',
        query: {
          next: _vm.$route.query.next,
        },
        params: {
          accessCampaign: _vm.$route.params.accessCampaign,
        },
      }},domProps:{"textContent":_vm._s(_vm.$t('wallet.signIn.signup'))}}):_c('router-link',{staticClass:"link_signup",attrs:{"to":{ name: 'WalletRegister' }},domProps:{"textContent":_vm._s(_vm.$t('wallet.signIn.signup'))}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }