<template>
  <div>
    <div class="d-flex align-center mb-3">
      <BaseFrontOfficeParagraph v-if="label" class="mr-2" v-text="label" />
      <slot name="tooltip" />
    </div>

    <v-text-field outlined rounded dense v-bind="$attrs" v-on="$listeners">
      <slot />
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "BaseFrontOfficeTextField",
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  min-width: 250px;
  border-radius: 25px;
  background: var(--cosmo-gradient-primary) !important;
}
::v-deep .v-input.error--text .v-input__slot {
  background: var(--v-walletAlertError-base) !important;
}
::v-deep .v-input__slot fieldset {
  border-radius: 25px;
  margin: 1px 2px 2px 2px !important;
  border: hidden;
  background-color: var(--v-walletGrey-lighten5);
}
::v-deep input {
  caret-color: var(--v-walletGrey-base);
}
::v-deep .v-input.error--text input {
  color: var(--v-walletAlertError-base) !important;
  caret-color: var(--v-walletAlertError-base) !important;
}
</style>
