<template>
  <div>
    <BaseDivider grey class="mt-8 mb-4" />
    <div class="d-flex justify-center my-4">
      <router-link
        v-if="$route.fullPath.indexOf('/wallet') != -1"
        :to="{
          name: 'WalletRegister',
          query: {
            next: $route.query.next,
          },
          params: {
            accessCampaign: $route.params.accessCampaign,
          },
        }"
        class="link_signup"
        v-text="$t('wallet.signIn.signup')"
      />
      <router-link
        v-else
        :to="{ name: 'WalletRegister' }"
        class="link_signup"
        v-text="$t('wallet.signIn.signup')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletHasToRegister",
};
</script>
<style scoped>
.link_signup {
  text-decoration: none;
}
.link_signup:hover {
  transition: all 0.75s ease;
  color: var(--v-walletAccent-base);
  text-decoration: underline;
}
</style>
