<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('campaign.forms.invite.title')"
    is-unique
    @close="reset"
  >
    <template #modal>
      <v-combobox
        v-model="form.emails"
        :items="suggestedSignatories"
        :filter="filterSignatory"
        :search-input.sync="queryText"
        class="email_input elevation-0"
        chips
        clearable
        :label="$t('folder.askInformationForm.email')"
        multiple
        outlined
        dense
        item-value="email"
        :error-messages="emailErrors"
        @input="testComboboxEmail"
        @change="handleEmailsChange()"
        @blur="$v.form.emails.$touch()"
      >
        <template #selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            class="mail-chips"
            :input-value="selected"
            close
            :color="isError(item) ? 'red' : ''"
            :text-color="isError(item) ? 'white' : ''"
            @click="select"
            @click:close="remove(item)"
          >
            <strong> {{ item }}</strong>
          </v-chip>
        </template>
        <template #item="{ item }">
          <div class="signatory_item">
            <BaseIcon class="icon" :icon="item.icon" />
            <div class="email" v-text="item.email" />
            <div
              v-if="item.lastName || item.firstname"
              class="separator"
              v-text="'-'"
            />
            <div v-if="item.lastName" class="name" v-text="item.lastName" />
            <div
              v-if="item.firstName"
              class="firstname"
              v-text="item.firstName"
            />
          </div>
        </template>
      </v-combobox>
      <!-- email list -->

      <BaseMarkdownEditor
        v-model="form.message"
        :label="`${$t('utils.mailContent')}*`"
        class="editor-content"
        :with-link="false"
      />

      <v-switch
        v-model="form.createFolder"
        :label="$t('campaign.forms.invite.createFolder')"
        inset
      />
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :disabled="form.emails.length < 1"
        type="primary"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import BaseMarkdownEditor from "../../../core/Components/UI/Markdown/BaseMarkdownEditor.vue";
import { Signatory, SIGNATORY_TYPE } from "@/core/Models/Signatory";
import DashboardAPI from "@/modules/Authentication/Services/dashboard.api";

export default {
  name: "CampaignInviteDialog",
  components: { BaseMarkdownEditor },

  mixins: [validationMixin],

  data() {
    return {
      form: {
        emails: [],
        createFolder: true,
        message: null,
      },
      queryText: "",
      suggestedSignatories: [],
    };
  },
  validations: {
    form: {
      emails: {
        minLength: minLength(1),
        $each: {
          required,
          maxLength: maxLength(255),
          email,
        },
      },
    },
  },

  computed: {
    ...mapState({
      activeCourse: (state) => state.course.course,
      activeCampaign: (state) => state.course.activeCampaign,
      dialogState: (state) => state.course.modals.isCampaignInviteDialogOpen,
    }),
    emailErrors() {
      if (!this.$v.form.emails.$dirty) {
        return "";
      }
      if (!this.form.emails?.length) {
        return this.$t("folder.askInformationForm.validation.emailRequired");
      }
      for (let i = 0; i < this.form.emails?.length; i++) {
        if (!this.$v.form.emails.$each.$iter[i.toString()].$dirty) {
          return "";
        }
        if (!this.$v.form.emails.$each.$iter[i.toString()].required) {
          return this.$t("folder.askInformationForm.validation.emailRequired");
        }
        if (!this.$v.form.emails.$each.$iter[i.toString()].email) {
          return this.$t("folder.askInformationForm.validation.emailInvalid");
        }
      }
      return "";
    },

    //#endregion
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.form.message = this.activeCampaign?.defaultEmailContent;
      await this.fetchSignatories();
    },
    isError(item) {
      const index = this.form.emails?.indexOf(item);
      let error = false;
      if (
        !this.$v.form.emails.$each.$iter[index.toString()].required ||
        !this.$v.form.emails.$each.$iter[index.toString()].email
      ) {
        return (error = true);
      }
      return error;
    },
    testComboboxEmail(item) {
      let newItem;
      if (Array.isArray(item)) newItem = [...item].pop();
      if (newItem?.email) {
        this.form.emails.pop();
        return this.form.emails.push(newItem?.email);
      }

      if (this.form.emails[item.length - 1]?.includes(",")) {
        const input = this.form.emails[item.length - 1].split(",");
        this.form.emails.pop();
        this.form.emails = [...this.form.emails, ...input];
      }
      if (this.form.emails[item.length - 1]?.includes(" ")) {
        const input = this.form.emails[item.length - 1].split(" ");
        this.form.emails.pop();
        this.form.emails = [...this.form.emails, ...input];
      }
    },
    remove(item) {
      this.form.emails.splice(this.form.emails.indexOf(item), 1);
    },
    reset() {
      this.form = {
        emails: [],
        createFolder: true,
        message: null,
      };
      this.$store.commit("course/setCampaignInviteDialog", false);
    },
    async fetchSignatories() {
      this.fetchingsSuggestedSignatories = true;
      try {
        const tmpSignatories = await Promise.all([
          await DashboardAPI.getUserSignatories(),
          await DashboardAPI.getOrganizationSignatories({
            organizationId: this.$route.params.organizationId,
          }),
        ]);
        this.suggestedSignatories = [
          ...tmpSignatories[0].data.results.map(
            (s) =>
              new Signatory({ ...s, type: SIGNATORY_TYPE.PERSONAL_SIGNATORY })
          ),
          ...tmpSignatories[1].data.results.map(
            (s) =>
              new Signatory({
                ...s,
                type: SIGNATORY_TYPE.ORGANIZATION_SIGNATORY,
              })
          ),
        ];
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.fetchingsSuggestedSignatories = false;
    },
    submit() {
      const payload = {
        emails: [...this.form.emails],
        create_folder: this.form.createFolder,
        email_content: this.form.message ? this.form.message : "",
      };
      this.$store
        .dispatch("course/postOrganizationCourseCampaignInvite", {
          organizationId: this.$route.params.organizationId,
          courseId: this.activeCourse.id,
          campaignId: this.activeCampaign.id,
          payload,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("campaign.forms.invite.success"),
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
      this.reset();
    },
    handleEmailsChange() {
      this.$v.form.emails.$touch();
      this.queryText = "";
    },
    filterSignatory(item, queryText) {
      if (!queryText) return true;
      return (
        item.email &&
        item.email.toLowerCase().includes(queryText.trim().toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .email_input .v-input__icon--append {
  display: none;
}
::v-deep .v-input__slot .v-select__selections {
  margin: 0 !important;
}
.mail-chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}
.red {
  background: #fef9fa !important;
  color: #ff5267 !important;
  ::v-deep .v-chip__close {
    color: #ff5267;
  }
}
.editor-content {
  margin-top: -2px;
}
.signatory_item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  color: #323338;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  .separator {
    margin: 0;
  }
  .icon {
    ::v-deep {
      .v-icon__svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .name {
    text-transform: uppercase;
  }
  .firstname {
    text-transform: capitalize;
  }
}
</style>
