<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('folder.workflow.addCosmoField')"
    is-complex
    @close="!submitLoading && reset()"
  >
    <template #modal>
      <v-form ref="form" @submit.prevent="submit()">
        <span class="sub-title" v-text="$t('utils.fieldSettings')" />
        <div class="input-fields">
          <!-- FIELD TYPE -->
          <base-autocomplete
            v-model="form.fieldType"
            :items="fieldTypes"
            item-text="label"
            item-value="type"
            :label="`${$t('utils.fieldType')}*`"
            :error-messages="fieldTypeErrors"
            :has-error="fieldTypeErrors.length > 0"
            @change="$v.form.fieldType.$touch()"
            @blur="$v.form.fieldType.$touch()"
          />
          <!-- DATATYPE -->
          <base-autocomplete
            v-if="form.fieldType == FIELD_TYPE.DATATYPE"
            v-model="form.datatype"
            :loading="isFetchingDatatype"
            :items="cosmoformTypes"
            item-text="name"
            item-value="id"
            :label="`${$t('utils.fieldType')}*`"
            :error-messages="cosmoformtypeErrors"
            :has-error="cosmoformtypeErrors.length > 0"
            @change="setCosmoformDefaultValues()"
            @blur="$v.form.datatype.$touch()"
          />
          <!-- QUESTION TEMPLATE -->
          <base-autocomplete
            v-else-if="form.fieldType == FIELD_TYPE.QUESTION_TEMPLATE"
            v-model="form.questionTemplate"
            :loading="questionTemplateFilter.loading"
            :items="questionTemplate.items"
            :filter="() => true"
            :search-input.sync="questionTemplateFilter.slug"
            item-text="name"
            item-value="id"
            :label="`${$t('utils.questionTemplates')}*`"
            :error-messages="questionTemplateErros"
            :has-error="questionTemplateErros.length > 0"
            @change="questionTemplateChange()"
          >
            <template #append-item>
              <div v-intersect="loadMoreQuestionTemplates" />
            </template>
          </base-autocomplete>
          <!-- CUSTOM FIELD -->
          <base-autocomplete
            v-else-if="form.fieldType == FIELD_TYPE.CUSTOM_FIELD"
            v-model="form.type"
            :items="typeItems"
            :placeholder="$t('question.chooseAType')"
            item-text="name"
            item-value="type"
            :label="'Type de question *'"
            :error-messages="typeErrors"
            :has-error="typeErrors.length > 0"
            @change="$v.form.type.$touch()"
            @blur="$v.form.type.$touch()"
          />
          <WorkflowQuestionCategory
            v-model="form.categoryId"
            place-holder="Créer ou choisissez une catégorie"
            :workflow-id="activeWorkflow ? activeWorkflow.id : NaN"
            :status-id="activeStatus ? activeStatus.id : NaN"
            :organization-id="parseInt($route.params.organizationId, 10)"
            @onFocus="onFocus()"
          />
          <!-- IS READ_ONLy -->
          <v-switch
            v-model="form.readOnly"
            class="checkbox"
            inset
            hide-details
            @change="disableRequire"
          >
            <template #label>
              <div class="checkbox_label">
                <span v-text="$t('utils.readOnlyField')" />
                <BaseIcon medium icon="$mdiPencilOffOutline" color="#CFCFDC" />
              </div>
            </template>
          </v-switch>

          <!-- IS REQUIRED -->
          <v-switch
            v-model="form.required"
            inset
            :disabled="isRequiredFieldDisable"
            class="checkbox"
            hide-details
          >
            <template #label>
              <div class="checkbox_label">
                <span v-text="$t('utils.fieldIsRequired')" />
                <BaseIcon medium icon="$mdiInformation" color="#CFCFDC" />
              </div>
            </template>
          </v-switch>
        </div>
        <span class="sub-title" v-text="$t('utils.fieldCustomization')" />
        <div class="input-fields">
          <!-- SLUG -->
          <BaseTextField
            v-model="form.slug"
            :label="$t('utils.dataSlug')"
            :error-messages="slugErrors"
            :has-error="slugErrors.length > 0"
            :disabled="
              [FIELD_TYPE.DATATYPE, FIELD_TYPE.QUESTION_TEMPLATE].includes(
                form.fieldType
              )
            "
            @blur="$v.form.slug.$touch()"
          />
          <!-- NAME -->
          <BaseTextField
            v-model="form.name"
            :label="`${$t('utils.dataName')}*`"
            :error-messages="nameErrors"
            :has-error="nameErrors.length > 0"
            @change="$v.form.name.$touch()"
            @blur="$v.form.name.$touch()"
          />
          <!-- LABEL -->
          <BaseTextField
            v-model="form.label"
            :label="`${$t('utils.label')}*`"
            :error-messages="labelErrors"
            :has-error="labelErrors.length > 0"
            @change="$v.form.label.$touch()"
            @blur="$v.form.label.$touch()"
          />
        </div>
        <!-- Options -->
        <div v-if="isQuestionRequireOptions">
          <span class="sub-title" v-text="'Options'" />
          <div class="input-fields">
            <div v-if="!isFetchingOptions">
              <div
                v-for="(v, index) in $v.form.options.$each.$iter"
                :key="index"
                class="option_item"
              >
                <BaseTextField
                  :ref="`option${+index + 1}`"
                  v-model.trim="form.options[index].name"
                  :error-messages="optionErrors[index]"
                  :label="
                    $t('course.forms.question.option.label', {
                      number: +index + 1,
                    })
                  "
                  :has-error="optionErrors[index].length > 0"
                  :placeholder="
                    $t('course.forms.question.option.placeholder', {
                      number: +index + 1,
                    })
                  "
                  clearable
                  class="option_name"
                  @input="v.name.$touch()"
                  @blur="v.name.$touch()"
                  @keydown.enter="() => (v.name.$model ? addOption() : null)"
                />
                <BaseTextField
                  v-model.trim="form.options[index].slug"
                  :disabled="form.fieldType == FIELD_TYPE.QUESTION_TEMPLATE"
                  :label="
                    $t('course.forms.question.option.slug', {
                      number: +index + 1,
                    })
                  "
                  :placeholder="
                    $t('course.forms.question.option.slug', {
                      number: +index + 1,
                    })
                  "
                  :error-messages="optionSlugErrors[index]"
                  :has-error="optionSlugErrors[index].length > 0"
                  clearable
                  class="option_slug"
                  @input="v.slug.$touch()"
                />
                <div class="option_item_actions">
                  <BaseButtonIcon
                    :disabled="index == 0 || isQuestionTemplate"
                    color="primary"
                    class="rounded"
                    icon="$mdiArrowUpThick"
                    x-small
                    @click="moveOption(+index, false)"
                  />
                  <BaseButtonIcon
                    :disabled="
                      index ==
                        Object.keys($v.form.options.$each.$iter).length - 1 ||
                      isQuestionTemplate
                    "
                    color="primary"
                    class="rounded mx-2"
                    icon="$mdiArrowDownThick"
                    x-small
                    @click="moveOption(+index, true)"
                  />
                  <BaseButtonIcon
                    :disabled="form.options.length < 2 || isQuestionTemplate"
                    color="secondary"
                    x-small
                    icon="$mdiTrashCanOutline"
                    @click="deleteOption(index)"
                  />
                </div>
              </div>
              <div v-if="has_free_option" class="option_item">
                <BaseTextField
                  :value="$t('utils.other')"
                  :label="
                    $t('course.forms.question.option.label', {
                      number: +form.options.length + 1,
                    })
                  "
                  :disabled="has_free_option"
                  clearable
                  class="option_name"
                />
                <div class="option_item_actions">
                  <BaseButtonIcon
                    :disabled="has_free_option"
                    color="primary"
                    class="rounded"
                    icon="$mdiArrowUpThick"
                    x-small
                  />
                  <BaseButtonIcon
                    :disabled="has_free_option"
                    color="primary"
                    class="rounded ml-2"
                    icon="$mdiArrowDownThick"
                    x-small
                  />
                  <BaseButtonIcon
                    color="error"
                    class="ml-2"
                    x-small
                    icon="$mdiTrashCanOutline"
                    @click="has_free_option = false"
                  />
                </div>
              </div>

              <div class="d-flex justify-center">
                <BaseButton
                  :disabled="isQuestionTemplate"
                  icon="$mdiPlus"
                  class="mr-3"
                  @click="addOption()"
                >
                  {{ $t("course.forms.question.utils.addOption") }}
                </BaseButton>
                <BaseButton
                  v-if="
                    (form.type === 'RADIOBUTTON' || form.type === 'CHECKBOX') &&
                    !has_free_option
                  "
                  icon="$mdiPlus"
                  :disabled="isQuestionTemplate"
                  @click="addOptionOther()"
                >
                  {{ $t("course.forms.question.utils.addOptionOther") }}
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <div
                v-for="n in 3"
                :key="n"
                class="d-flex justify-space-between align-center mb-3"
              >
                <BaseSkeletonLoader type="chip" />
                <div class="d-flex flex-wrap align-center">
                  <BaseSkeletonLoader
                    height="20px"
                    width="20px"
                    type="image"
                    class="mr-2"
                  />
                  <BaseSkeletonLoader
                    height="20px"
                    width="20px"
                    type="image"
                    class="mr-2"
                  />
                  <BaseSkeletonLoader height="20px" width="20px" type="image" />
                </div>
              </div>
              <div class="d-flex justify-center">
                <BaseSkeletonLoader type="button" />
              </div>
            </div>
          </div>
        </div>
        <!-- Question answer field -->

        <div v-if="$v.form.type && !$v.form.type.$invalid">
          <span
            class="sub-title"
            v-text="$tc('folder.workflow.fieldValue', 2)"
          />
          <div class="input-fields">
            <QuestionForm
              v-model="answer"
              :focus="false"
              :question="computedQuestion"
            />
          </div>
        </div>

        <div v-if="isHelpSectionOpen" class="input-fields">
          <!-- HELP SECTION -->
          <div class="help_section">
            <div class="d-flex align-center mb-4">
              <BaseIcon
                class="mr-2"
                medium
                icon="$mdiInformation"
                color="#353542"
              />
              <div class="help_title" v-text="$t('utils.help')" />
              <BaseIcon
                icon="$mdiClose"
                color="#CFCFDC"
                class="ml-auto"
                width="14"
                height="14"
                @click.prevent="
                  () => {
                    isHelpSectionOpen = false;
                    form.helpText = '';
                    form.helpFile = null;
                  }
                "
              />
            </div>
            <BaseTextArea
              v-model="form.helpText"
              rows="3"
              :label="$t('course.forms.question.helpText.label')"
            />
            <BaseFileInput
              :val="form.helpFile"
              truncate-length="50"
              append-icon="$mdiDownload"
              label="Fichier d'aide"
              prepend-icon=""
              :clearable="false"
              outlined
              @change="(value) => (form.helpFile = value)"
            />
          </div>
        </div>
      </v-form>
    </template>

    <template #actions>
      <BaseButton
        v-if="!isHelpSectionOpen"
        outlined
        type="secondary"
        color="primary"
        @click="isHelpSectionOpen = true"
      >
        Ajouter une aide
      </BaseButton>
      <BaseButton text class="mx-2 ml-auto" color="#707080" @click="reset">
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        :loading="submitLoading"
        color="primary"
        type="primary"
        @click="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import QuestionForm from "./QuestionForm";
import WorkflowQuestionCategory from "./../../Admin/Components/WorkflowQuestionCategory.vue";

import { validationMixin } from "vuelidate";

import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  fetchQuestionTemplates,
  getQuestionTemplateOption,
} from "@/core/Services/questionTemplate.service";

const FIELD_TYPE = {
  DATATYPE: "DATATYPE",
  QUESTION_TEMPLATE: "QUESTION_TEMPLATE",
  CUSTOM_FIELD: "CUSTOM_FIELD",
};

export default {
  name: "FolderStatusQuestionCreateDialog",
  components: {
    QuestionForm,
    WorkflowQuestionCategory,
  },
  mixins: [validationMixin],
  data() {
    return {
      isFocused: false,
      isFetchingOptions: false,
      answer: null,
      isRequiredFieldDisable: false,
      isHelpSectionOpen: false,
      form: {
        fieldType: "",
        questionTemplate: "",
        helpText: "",
        helpFile: null,
        categoryId: null,
        label: "",
        name: "",
        slug: "",
        type: "",
        options: [],
        deletedOptionIds: [],
        readOnly: false,
        required: false,
        datatype: null,
      },
      FIELD_TYPE: FIELD_TYPE,
      fieldTypes: [
        {
          type: FIELD_TYPE.DATATYPE,
          label: this.$t("utils.datatypes"),
        },
        {
          type: FIELD_TYPE.QUESTION_TEMPLATE,
          label: this.$t("utils.questionTemplates"),
        },
        {
          type: FIELD_TYPE.CUSTOM_FIELD,
          label: this.$t("utils.customFields"),
        },
      ],
      typeItems: [
        { name: this.$t("question.types.text"), type: "TEXT" },
        { name: this.$t("question.types.textarea"), type: "TEXTAREA" },
        { name: this.$t("question.types.number"), type: "NUMBER" },
        { name: this.$t("question.types.date"), type: "DATE" },
        { name: this.$t("question.types.datetime"), type: "DATETIME" },
        { name: this.$t("question.types.select"), type: "SELECT" },
        { name: this.$t("question.types.checkbox"), type: "CHECKBOX" },
        { name: this.$t("question.types.radio"), type: "RADIOBUTTON" },
        { name: this.$t("question.types.file"), type: "FILE" },
        { name: this.$t("question.types.multiFile"), type: "MULTIPLE_FILE" },
        { name: this.$t("question.types.signature"), type: "SIGNATURE" },
        { name: this.$t("question.types.datagrid"), type: "DATAGRID" },
        { name: this.$t("question.types.rowDatagrid"), type: "ROW_DATAGRID" },
      ],
      questionFormCloseEvent: false,
      submitLoading: false,
      isFetchingDatatype: true,
      has_free_option: false,
      questionTemplate: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      questionTemplateFilter: {
        loading: false,
        page: 1,
        pageSize: 10,
        slug: undefined,
        searchTimeoutRef: undefined,
      },
    };
  },
  validations() {
    let validations = {};
    validations.form = {
      name: {
        required,
        maxLength: maxLength(255),
      },
      slug: {
        maxLength: maxLength(255),
        notSpecialChar: (value) => {
          return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
        },
      },
      label: {
        required,
        maxLength: maxLength(255),
      },
      fieldType: {
        required,
      },
    };
    switch (this.form.fieldType) {
      case FIELD_TYPE.DATATYPE:
        validations.form = {
          ...validations.form,
          datatype: {
            required,
          },
        };
        break;
      case FIELD_TYPE.QUESTION_TEMPLATE:
        validations.form = {
          ...validations.form,
          questionTemplate: {
            required,
          },
        };
        break;
      case FIELD_TYPE.CUSTOM_FIELD:
        validations.form = {
          ...validations.form,
          type: {
            required,
          },
        };
        break;
    }
    if (this.isQuestionRequireOptions) {
      validations.form = {
        ...validations.form,
        options: {
          minLength: minLength(1),
          $each: {
            name: {
              required,
              maxLength: maxLength(255),
            },
            slug: {
              required: requiredIf(() => !!this.form?.slug),
              maxLength: maxLength(255),
              notSpecialChar: (value) => {
                return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
              },
              isUniqueSlug: (value) => {
                return value
                  ? this.form.options.filter((o) => o.slug == value).length <= 1
                  : true;
              },
            },
          },
        },
      };
    }
    return validations;
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isStatusQuestionCreateOpen,
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      activeStatus: (state) => state.workflow.activeStatus,
      datatypes: (state) => state.datatype.datatypes,
      statusCategory: (state) => state.workflow.activeStatusCategory,
    }),
    isQuestionTemplate() {
      return this.form.fieldType == FIELD_TYPE.QUESTION_TEMPLATE;
    },
    computedQuestion() {
      return {
        label: this.form.label,
        name: "",
        helpText: this.form.helpText,
        helpFile: this.form.helpFile,
        typeQuestion: this.form.type,
        choices: this.form.options.map((option, index) => {
          return {
            id: index,
            name: option.name,
          };
        }),
        readOnly: this.form.readOnly,
        required: this.form.required,
      };
    },
    isQuestionRequireOptions() {
      const requireOptionsRegex =
        /^SELECT|RADIOBUTTON|CHECKBOX|DATAGRID|ROW_DATAGRID$/;
      return this.form.type && requireOptionsRegex.test(this.form.type);
    },
    fieldTypeErrors() {
      if (!this.$v.form.fieldType.$dirty) return [];
      if (!this.$v.form.fieldType.required)
        return this.$t("course.forms.question.type.required");
      return [];
    },
    //#region Form Errors
    typeErrors() {
      const errors = [];
      if (!this.$v.form.type.$dirty) {
        return errors;
      }
      if (!this.$v.form.type.required) {
        errors.push(this.$t("course.forms.question.type.required"));
      }
      return errors;
    },
    cosmoformtypeErrors() {
      const errors = [];
      if (!this.$v.form.datatype.$dirty) {
        return errors;
      }
      if (!this.$v.form.datatype.required) {
        errors.push("Un type de champ est requis");
      }
      return errors;
    },
    questionTemplateErros() {
      if (!this.$v.form.questionTemplate.$dirty) return [];
      if (!this.$v.form.questionTemplate.required)
        return this.$t("course.forms.question.type.required");
      return [];
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) {
        return errors;
      }
      if (!this.$v.form.name.required) {
        errors.push(this.$t("course.forms.question.name.required"));
      }
      if (!this.$v.form.name.maxLength) {
        errors.push(
          this.$t("course.forms.question.name.maxLength", { n: 255 })
        );
      }
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.form.slug.$dirty) return errors;
      if (!this.$v.form.slug.maxLength) {
        errors.push(
          this.$t("course.forms.question.slug.maxLength", { n: 255 })
        );
      }
      if (!this.$v.form.slug.notSpecialChar) {
        errors.push(
          this.$t("course.forms.question.slug.specialCharError", { n: 255 })
        );
      }
      return errors;
    },
    labelErrors() {
      const errors = [];
      if (!this.$v.form.label.$dirty) {
        return errors;
      }
      if (!this.$v.form.label.required) {
        errors.push(this.$t("course.forms.question.label.requiredQuestion"));
      }
      if (!this.$v.form.label.maxLength) {
        errors.push(
          this.$t("course.forms.question.label.maxLengthQuestion", { n: 255 })
        );
      }
      return errors;
    },
    optionErrors() {
      const errors = [];
      if (!this.$v.form.options) {
        return errors;
      }
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        const option = this.$v.form.options.$each.$iter[index.toString()];
        if (!option.name.$dirty) {
          continue;
        }
        if (!option.name.maxLength) {
          errors[index] = [
            this.$t("course.forms.question.option.maxLength", { number: 255 }),
          ];
          continue;
        }
        if (!option.name.required) {
          errors[index] = [this.$t("course.forms.question.option.required")];
        }
      }
      return errors;
    },
    optionSlugErrors() {
      const errors = [];
      if (!this.$v.form.options) return errors;
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        const option = this.$v.form.options.$each.$iter[index.toString()];
        if (!option.slug.$dirty) continue;
        if (!option.slug.required) {
          errors[index].push(this.$t("utils.fieldIsRequired"));
          continue;
        }
        if (!option.slug.maxLength) {
          errors[index] = [
            this.$t("course.forms.question.slug.maxLength", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.notSpecialChar) {
          errors[index] = [
            this.$t("course.forms.question.slug.specialCharError", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.isUniqueSlug) {
          errors[index] = [
            this.$t("course.forms.question.slug.duplicatedSlug"),
          ];
          continue;
        }
      }
      return errors;
    },
    cosmoformTypes() {
      if (!this.datatypes.length) return [];
      if (!this.activeStatus || !this.activeStatus.questions)
        return [...this.datatypes];
      return this.datatypes.filter((datatype) => {
        let alreadyTaken = false;
        for (const question of this.activeStatus.questions) {
          if (question.datatype && question.datatype.id === datatype.id) {
            alreadyTaken = true;
            break;
          } else if (
            question.datatype &&
            !isNaN(question.datatype) &&
            question.datatype === datatype.id
          ) {
            alreadyTaken = true;
            break;
          }
        }
        return !alreadyTaken ? datatype : null;
      });
    },
  },
  watch: {
    isQuestionRequireOptions(newVal) {
      if (newVal && this.form.options.length < 1) {
        this.addOption();
      }
    },
    dialogState(value) {
      if (value) {
        this.initialize();
      }
    },
    "questionTemplateFilter.slug"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterQuestionTemplates();
    },
  },
  methods: {
    onFocus() {
      this.isFocused = !this.isFocused;
    },
    disableRequire(event) {
      if (event) {
        this.form.required = false;
        return (this.isRequiredFieldDisable = true);
      }
      this.form.required = false;
      return (this.isRequiredFieldDisable = false);
    },
    reset() {
      this.submitLoading = false;
      this.questionFormCloseEvent = false;
      this.form.fieldType = null;
      this.form.questionTemplate = null;
      this.form.helpText = "";
      this.form.helpFile = null;
      this.form.categoryId = null;
      this.form.readOnly = false;
      this.form.required = false;
      this.form.label = "";
      this.form.name = "";
      this.form.slug = "";
      this.form.options = [];
      this.form.deletedOptionIds = [];
      this.form.type = "";
      this.form.datatype = null;
      this.has_free_option = false;
      this.answer = null;
      this.$v.$reset();
      this.$store.commit("workflow/setActiveStatus", null);
      this.$store.commit("folder/setModalStatusQuestionCreateDialog", false);
    },
    async initialize() {
      await this.fetchQuestionTemplates();
      await this.fecthDatatypes();
      this.initializeForm();
    },
    async fetchQuestionTemplates() {
      this.questionTemplateFilter.loading = true;
      try {
        this.questionTemplateFilter.page = 1;
        this.questionTemplate = await fetchQuestionTemplates({
          organizationId: this.$route.params.organizationId,
          page: this.questionTemplateFilter.page,
          slug: this.questionTemplateFilter.slug,
          pageSize: this.questionTemplateFilter.pageSize,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    filterQuestionTemplates() {
      if (this.questionTemplateFilter.searchTimeoutRef)
        clearTimeout(this.questionTemplateFilter.searchTimeoutRef);
      this.questionTemplateFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchQuestionTemplates();
      }, 500);
    },
    async loadMoreQuestionTemplates(_, __, isIntersecting) {
      if (
        !isIntersecting ||
        this.questionTemplateFilter.loading ||
        !this.questionTemplate.next
      )
        return;
      this.questionTemplateFilter.page = this.questionTemplate.next;
      this.questionTemplateFilter.loading = true;
      try {
        const tmp = await fetchQuestionTemplates({
          organizationId: this.$route.params.organizationId,
          page: this.questionTemplateFilter.page,
          slug: this.questionTemplateFilter.slug,
          pageSize: this.questionTemplateFilter.pageSize,
        });
        this.questionTemplate = {
          ...tmp,
          items: [...this.questionTemplate.items, ...tmp.items],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    async fecthDatatypes() {
      this.isFetchingDatatype = true;
      try {
        await this.$store.dispatch("datatype/fetchDatatypes");
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isFetchingDatatype = false;
    },
    initializeForm() {
      this.form.categoryId = this.statusCategory
        ? this.statusCategory.id
        : null;
    },
    addOption() {
      this.form.options.push({ order: this.form.options.length });
    },
    addOptionOther() {
      this.has_free_option = true;
    },
    deleteOption(index) {
      /* saved options in database to delete */
      if (this.form.options[index].id) {
        this.form.deletedOptionIds.push(this.form.options[index].id);
      }
      this.form.options.splice(index, 1);
      this.orderOptions();
    },
    buildQuestion() {
      let order = 1;
      if (this.activeStatus.questions.length > 0) {
        order =
          Math.max(
            ...this.activeStatus.questions.map((question) => question.order)
          ) + 1;
      }

      let question = {
        label: this.form.label,
        helpText: this.form.helpText,
        helpFile: this.form.helpFile,
        category: this.form.categoryId,
        readOnly: this.form.readOnly,
        required: this.form.required ? this.form.required : false,
        name: this.form.name,
        slug: this.form.slug,
        order: order,
        has_free_option: this.has_free_option,
        datatype: this.form.datatype,
        typeQuestion: this.form.type,
      };

      if (this.isQuestionRequireOptions) {
        question.choices = this.form.options;
      }
      if (this.form.image) {
        question.image = this.form.image;
      }
      if (this.isQuestionRequireOptions) {
        this.orderOptions();
      }
      return question;
    },
    async submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.submitLoading = true;

        try {
          // Create the question
          const question = await this.$store.dispatch(
            `folder/${
              !this.form.datatype
                ? "createStatusQuestion"
                : "createStatusDatatypeQuestion"
            }`,
            {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
              statusId: this.activeStatus.id,
              payload: this.buildQuestion(),
            }
          );
          this.$store.dispatch("workflow/fetchFolderQuestions", {
            organizationId: this.$route.params.organizationId,
            folderId: this.$route.params.folderId,
            workflowId: this.activeWorkflow.id,
            statusId: this.activeStatus.id,
          });
          if (this.answer?.choices) {
            if (Number.isSafeInteger(this.answer.choices)) {
              this.answer.choices = question.choices[this.answer.choices].id;
            } else {
              this.answer.choices = [...this.answer.choices].map(
                (choiceIndex) => {
                  return question.choices[choiceIndex].id;
                }
              );
            }
          }
          // Create answer

          if (
            this.answer &&
            (this.answer.content || this.answer.choices.length)
          ) {
            await this.$store.dispatch("workflow/postQuestionAnswer", {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
              statusId: this.activeStatus.id,
              questionId: question.id,
              payload: this.buildContent(this.answer, question),
            });
            this.questionFormCloseEvent = true;
          }
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: "Champ créé avec succès",
            type: "SUCCESS",
          });
        } catch (error) {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        }
        this.reset();
      }
    },
    buildContent(payload, question) {
      const withChoices = ["RADIOBUTTON", "SELECT", "CHECKBOX"];
      if (withChoices.includes(question.typeQuestion)) {
        const result = Number.isSafeInteger(this.answer.choices)
          ? [payload.choices]
          : [...payload.choices];
        return { set_content: result };
      }
      if (question.typeQuestion === "DATAGRID") {
        return { set_content: payload.content };
      }
      if (question.typeQuestion === "FILE") {
        return {
          set_content: [
            { name: payload.fileName, encoded_file: payload.content },
          ],
        };
      }
      if (question.typeQuestion === "MULTIPLE_FILE") {
        return {
          set_content: payload.content.map((file) => ({
            name: file.name,
            encoded_file: file.content,
          })),
        };
      }
      return { set_content: [payload.content] };
    },
    moveOption(index, upperOrder) {
      let temp = this.form.options[index];
      this.form.options.splice(
        index,
        1,
        this.form.options[upperOrder ? index + 1 : index - 1]
      );
      this.form.options.splice(upperOrder ? index + 1 : index - 1, 1, temp);
      this.orderOptions();
    },
    orderOptions() {
      this.form.options.forEach((option, index) => {
        option.order = index;
      });
    },
    setCosmoformDefaultValues() {
      this.$v.form.datatype.$touch();
      let datatype = this.datatypes.find((dt) => dt.id === this.form.datatype);
      if (!datatype) return;
      this.form.name = datatype.name;
      this.form.slug = datatype.slug;
      this.form.label = datatype?.label || datatype.name;
      this.form.options = [];
    },
    questionTemplateChange() {
      this.$v.form.questionTemplate.$touch();
      const questionTemplate = this.questionTemplate.items.find(
        (qt) => qt.id == this.form.questionTemplate
      );
      if (!questionTemplate) return;
      this.form.name = questionTemplate.name;
      this.form.type = questionTemplate.typeQuestion;
      this.form.slug = questionTemplate.slug;
      this.form.label = questionTemplate.label;
      this.fetchQuestionTemplateOption(questionTemplate.id);
    },
    async fetchQuestionTemplateOption(questionTemplateId) {
      if (!this.isQuestionRequireOptions) return;
      this.isFetchingOptions = true;
      try {
        this.form.options = await getQuestionTemplateOption({
          organizationId: this.$route.params.organizationId,
          questionTemplateId: questionTemplateId,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isFetchingOptions = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.checkbox {
  margin: 0 0 16px 0 !important;
  .checkbox_label {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 8px;
    }
  }
}
.help_section {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 8px;
  min-height: 255px;
  background: #fcfcfc;
  border: 1px solid #cfcfdc;
  border-radius: 5px;
  .help_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353542;
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.input-fields {
  padding: 16px 16px 0 16px;
}
.text--blue {
  color: blue !important;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.category {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707080;

  &.focused {
    color: blue !important;
  }
}
.option_item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  .option_slug {
    flex: 1;
  }
  .option_slug {
    flex: none;
    width: 200px;
  }
  .option_item_actions {
    display: flex;
    align-items: center;
    flex: none;
    width: 76px;
    margin-top: -26px;
  }
}
</style>
