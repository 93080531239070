import WalletWelcome from "../Pages/WalletWelcome.vue";
import WalletImpersona from "../Pages/WalletImpersona.vue";
import WalletCourse from "../Pages/WalletCourse.vue";
import Wallet from "../Pages/Wallet.vue";
import WalletAssistant from "../Pages/WalletAssistant.vue";
import WalletSafeRecordDetail from "../Pages/WalletSafeRecordDetail.vue";
import WalletOrganizationRecords from "../Pages/WalletOrganizationRecords.vue";
import WalletAccount from "../Pages/WalletAccount.vue";
import WalletPasswordChange from "../Pages/Auth/WalletPasswordChange.vue";
import WalletLogin from "../Pages/Auth/WalletLogin.vue";
import WalletRegister from "../Pages/Auth/WalletRegister.vue";
import WalletDeleteAccount from "../Pages/Auth/WalletDeleteAccount.vue";
import WalletThanks from "../Pages/WalletThanks.vue";
import WalletReserved from "../Pages/WalletReserved.vue";
import PageNotFound from "../Pages/PageNotFound.vue";
import InternalServerError from "../Pages/InternalServerError.vue";
import WalletAuth from "../Pages/Auth/WalletAuth.vue";
import WalletAuthPassword from "../Pages/Auth/WalletAuthPassword.vue";
import WalletPasswordReset from "../Pages/Auth/WalletPasswordReset.vue";
import WalletPasswordResetDone from "../Pages/Auth/WalletPasswordResetDone.vue";
import WalletPasswordResetConfirm from "../Pages/Auth/WalletPasswordResetConfirm.vue";
import WalletPasswordResetComplete from "../Pages/Auth/WalletPasswordResetComplete.vue";
import WalletManagerSpace from "../Pages/WalletManagerSpace.vue";

export default [
  {
    path: "/impersona/",
    name: "WalletImpersona",
    component: WalletImpersona,
    props: true,
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: "/space-manager/offer/:uuid/",
    name: "space-manager",
    component: WalletManagerSpace,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: "/campaign/:campaignSlug/welcome/:recordID?",
    name: "WalletWelcome",
    component: WalletWelcome,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: "/campaign/:campaignId/reserved/",
    name: "WalletReserved",
    component: WalletReserved,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: "/record/:recordId/answer/",
    name: "WalletCourse",
    component: WalletCourse,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: "/record/:recordId/thanks/",
    name: "WalletThanks",
    component: WalletThanks,
    props: true,
  },

  {
    path: "/wallet",
    component: Wallet,

    children: [
      {
        path: "/",
        name: "WalletHome",
        component: WalletAssistant,
        props: true,
        meta: {
          authenticationRequired: true,
        },
      },
      {
        path: "organization/:organizationId",
        name: "WalletOrganizationRecords",
        component: WalletOrganizationRecords,
        props: true,
        children: [
          {
            path: "folder/:folderID/:offerID?",
            name: "WalletOrganizationRecordsFolder",
            component: WalletOrganizationRecords,
            props: true,
            meta: {
              authenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "record/:recordId/",
        name: "WalletSafeRecordDetail",
        component: WalletSafeRecordDetail,
        props: true,
        meta: {
          authenticationRequired: true,
        },
      },
      {
        path: "auth/",
        name: "WalletAuth",
        component: WalletAuth,
        children: [
          {
            path: "login/:next?",
            name: "WalletLogin",
            component: WalletLogin,
            props: true,
            meta: {
              authenticationRequired: false,
            },
          },
          {
            path: "signup/:next?",
            name: "WalletRegister",
            component: WalletRegister,
            props: true,
            meta: {
              authenticationRequired: false,
            },
          },
          {
            path: "profile/",
            name: "WalletAccount",
            component: WalletAccount,
            props: true,
            meta: {
              authenticationRequired: true,
            },
          },
          {
            path: "rm-rf/",
            name: "WalletDeleteAccount",
            component: WalletDeleteAccount,
            props: true,
            meta: {
              authenticationRequired: true,
            },
          },
          {
            path: "password/",
            name: "WalletAuthPassword",
            component: WalletAuthPassword,
            children: [
              {
                path: "reset/:next?",
                name: "WalletPasswordReset",
                component: WalletPasswordReset,
                props: true,
                meta: {
                  authenticationRequired: false,
                },
              },
              {
                path: "reset/done/:next?",
                name: "WalletPasswordResetDone",
                component: WalletPasswordResetDone,
                props: true,
                meta: {
                  authenticationRequired: false,
                },
              },
              {
                path: "reset/confirm/:token/",
                name: "WalletPasswordResetConfirm",
                component: WalletPasswordResetConfirm,
                props: true,
                meta: {
                  authenticationRequired: false,
                },
              },
              {
                path: "reset/complete/",
                name: "WalletPasswordResetComplete",
                component: WalletPasswordResetComplete,
                meta: {
                  authenticationRequired: false,
                },
              },
              {
                path: "change/",
                name: "WalletPasswordChange",
                component: WalletPasswordChange,
                props: true,
                meta: {
                  authenticationRequired: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: "/500",
    name: "InternalServerError",
    component: InternalServerError,
    props: true,
    meta: {
      authenticationRequired: false,
    },
  },
];
