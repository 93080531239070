<template>
  <BaseModal
    :value="dialogState"
    :modal-title="$t('client.create')"
    is-complex
    width="900"
    @close="reset"
  >
    <template #modal>
      <BaseTextField
        v-model="contact"
        autofocus
        :label="$t('client.label')"
        :has-error="clientError.length > 0"
        :error-messages="clientError"
        @change="$v.contact.$touch()"
        @blur="$v.contact.$touch()"
      />
    </template>
    <template #actions>
      <BaseButton
        text
        class="mx-2 ml-auto"
        type="secondary"
        color="#707080"
        @click="reset"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click="addContact">
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

import ClientAPI from "@/modules/Client/Services/client.api";

export default {
  name: "ClientCreateDialog",
  mixins: [validationMixin],
  data: function () {
    return {
      contact: null,
    };
  },
  validations: {
    contact: {
      required,
      email,
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.client.modals.isClientCreateDialogOpen,
    }),
    clientError() {
      if (!this.$v.contact.$dirty) {
        return "";
      }
      if (!this.$v.contact.required) {
        return this.$t("client.clientRequired");
      }
      if (!this.$v.contact.email) {
        return this.$t("client.clientEmailError");
      }
      return "";
    },
  },

  methods: {
    reset() {
      this.$store.commit("client/setDisplayClientDialog", false);
    },
    async addContact() {
      if (this.$v.contact.$invalid) return;
      try {
        this.isAdding = true;
        const newContact = (
          await ClientAPI.postOrganizationCreateClient(
            this.$route.params.organizationId,
            {
              email: this.contact,
            }
          )
        )?.data;
        this.$router.push({
          name: "clientDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            clientId: newContact.id,
          },
        });
      } catch (error) {
        const { statusText } = error?.response;
        this.$store.dispatch("snackbar/active", {
          message: statusText,
          type: "ERROR",
        });
      }
    },
  },
};
</script>
<style scoped>
.select-width {
  width: 100%;
}
</style>
