import { render, staticRenderFns } from "./TheDrawer.vue?vue&type=template&id=0231d78b&scoped=true"
import script from "./TheDrawer.vue?vue&type=script&lang=js"
export * from "./TheDrawer.vue?vue&type=script&lang=js"
import style0 from "./TheDrawer.vue?vue&type=style&index=0&id=0231d78b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0231d78b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
