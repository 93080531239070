import { render, staticRenderFns } from "./FolderStatusQuestionCreateDialog.vue?vue&type=template&id=4a872969&scoped=true"
import script from "./FolderStatusQuestionCreateDialog.vue?vue&type=script&lang=js"
export * from "./FolderStatusQuestionCreateDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderStatusQuestionCreateDialog.vue?vue&type=style&index=0&id=4a872969&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a872969",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VForm,VSwitch})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
