<template>
  <div
    class="item_header"
    :disabled="offer.status === 'CANCELED'"
    @click="changeExpandStatus()"
  >
    <div :class="getClassHeader">
      <BaseIcon icon="$mdiLogout" class="icon" />
    </div>
    <div class="sections">
      <div class="section_left">
        <div class="section_info">
          <div class="section_title" v-text="offer.title" />
          <div
            class="section_content"
            v-text="
              $t('folder.createdAt', {
                date: $d(new Date(offer.dateCreated), 'detailed'),
              })
            "
          />
          <div
            v-if="offer.publishDate && isPublished"
            class="section_content"
            v-text="
              $t('utils.scheduledOn', {
                date: $d(new Date(offer.publishDate), 'detailed'),
              })
            "
          />
        </div>
      </div>
      <div v-if="offer.publishDate && !isPublished" class="chips_container">
        <div class="scheduled_chips">
          <div class="chips_icon">
            <BaseIcon icon="$scheduledMail" />
          </div>
          <div
            class="chips_label"
            v-text="
              $t('utils.forDate', {
                date: $d(new Date(offer.publishDate), 'full'),
              })
            "
          />
        </div>
      </div>
      <div class="section_center">
        <div style="margin-left: 45px">
          <div class="section_title" v-text="$tc('utils.contact', 1)" />
          <template v-if="offerClients.length">
            <template v-if="offerClients.length == 1">
              <div class="section_content" v-text="offerClients[0].fullName" />
              <div
                class="section_footer"
                :class="{ text_primary: !isPending }"
                v-text="offerClients[0].email"
              />
            </template>
            <template v-else>
              <div
                class="section_content"
                v-text="
                  offerClients[0].fullName
                    ? offerClients[0].fullName
                    : offerClients[0].email
                "
              />
              <div
                v-if="otherContact"
                class="section_content"
                v-text="otherContact"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="section_right">
        <div class="chips" :class="offerStatus.chipsClass">
          {{ offerStatus.label }}
        </div>
      </div>
    </div>
    <div class="icon_right">
      <v-btn icon @click.stop="changeExpandStatus()">
        <BaseIcon :icon="expanded ? '$mdiChevronUp' : '$mdiChevronDown'" />
      </v-btn>
    </div>
  </div>
</template>
<script>
import { OFFER_TYPES } from "@/core/Utils/types.utils";

export default {
  name: "FolderOfferItemHeader",
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    isPending: {
      type: Boolean,
      default: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    offerClients: {
      type: Array,
      default: () => [],
    },
    isRefused: {
      type: Boolean,
    },
    isSpam: {
      type: Boolean,
    },
  },
  data() {
    return {
      OFFER_TYPES: OFFER_TYPES,
    };
  },
  computed: {
    getClassHeader() {
      if (this.isSpam) return "icon_left is_spam";
      if (this.offer.status === "CANCELED") return "icon_left chips_canceled";
      if (this.isRefused) return "icon_left refused";

      return "icon_left";
    },

    offerStatus() {
      let offerStatus = {
        label: "",
        chipsClass: "",
      };
      switch (this.offer.offerType) {
        case OFFER_TYPES.CONSULT:
          if (
            this.offer.clients.every((client) => client.status === "PENDING")
          ) {
            offerStatus.label = this.$t(
              "folder.offerStatus.waitingForConsultation"
            );
          } else if (
            this.offer.clients.some((client) => client.status === "PENDING")
          ) {
            const clientConsulted = this.offer.clients.filter(
              (client) => client.status !== "PENDING"
            );
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerConsulted",
              1,
              {
                client: `${clientConsulted.length}/${this.offer.clients.length}`,
              }
            );
          } else {
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerConsulted",
              0
            );
            offerStatus.chipsClass = "chips_success";
          }
          break;
        case OFFER_TYPES.VALIDATION:
          if (
            this.offer.clients.every((client) => client?.status === "DECLINE")
          ) {
            offerStatus.label = this.$tc("folder.offerStatus.offerDeclined", 0);
            offerStatus.chipsClass = "chips_danger";
          } else if (
            this.offer.clients.every((client) => client?.status === "PENDING")
          ) {
            offerStatus.label = this.$t(
              "folder.offerStatus.waitingForValidation"
            );
          } else if (
            this.offer.clients.every((client) => client?.status === "ACCEPT")
          ) {
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerValidated",
              0
            );
            offerStatus.chipsClass = "chips_success";
          } else if (
            this.offer.clients.some((client) => client?.status === "DECLINE")
          ) {
            const declinedClients = this.offer.clients?.filter(
              (client) => client.status === "DECLINE"
            );
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerDeclined",
              1,
              {
                client: `${declinedClients.length}/${this.offer.clients.length}`,
              }
            );
          } else if (
            this.offer.clients.some((client) => client?.status === "ACCEPT")
          ) {
            const acceptedClients = this.offer.clients.filter(
              (client) => client.status === "ACCEPT"
            );
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerValidated",
              1,
              {
                client: `${acceptedClients.length}/${this.offer.clients.length}`,
              }
            );
          }
          break;

        case OFFER_TYPES.MIXTE:
          if (
            this.offer.clients.every((client) => client?.status === "DECLINE")
          ) {
            offerStatus.label = this.$tc("folder.offerStatus.offerDeclined", 0);
            offerStatus.chipsClass = "chips_danger";
          } else if (
            this.offer.clients.every((client) => client?.status === "PENDING")
          ) {
            offerStatus.label = this.$t(
              "folder.offerStatus.waitingForValidation"
            );
          } else if (
            this.offer.clients.every((client) => client?.status === "ACCEPT")
          ) {
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerValidated",
              0
            );
            offerStatus.chipsClass = "chips_success";
          } else if (
            this.offer.clients.some((client) => client?.status === "DECLINE")
          ) {
            const declinedClients = this.offer.clients?.filter(
              (client) => client.status === "DECLINE"
            );
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerDeclined",
              1,
              {
                client: `${declinedClients.length}/${this.offer.clients.length}`,
              }
            );
          } else if (
            this.offer.clients.some((client) => client?.status === "ACCEPT")
          ) {
            const acceptedClients = this.offer.clients.filter(
              (client) => client.status === "ACCEPT"
            );
            offerStatus.label = this.$tc(
              "folder.offerStatus.offerValidated",
              1,
              {
                client: `${acceptedClients.length}/${this.offer.clients.length}`,
              }
            );
          }
          break;
        case OFFER_TYPES.SIGNATURE:
          if (this.offer.status === "PENDING" || this.offer.status == null) {
            offerStatus.label = this.$t(
              "folder.offerStatus.waitingForSignature"
            );
          } else if (this.offer.status === "DECLINE") {
            offerStatus.label = this.$tc("folder.offerStatus.offerDeclined", 0);
            offerStatus.chipsClass = "chips_danger";
          } else if (this.offer.status === "CANCELED") {
            offerStatus.label = this.$t("folder.offerStatus.offerCanceled");
            offerStatus.chipsClass = "chips_canceled";
          } else {
            offerStatus.label = this.$t("folder.offerStatus.OfferSigned");
            offerStatus.chipsClass = "chips_success";
          }
          break;
      }
      return offerStatus;
    },
    otherContact() {
      if (!this.offerClients || this.offerClients.length <= 1) return "";
      if (this.offerClients.length == 2)
        return `Et ${
          this.offerClients[1].fullName
            ? this.offerClients[1].fullName
            : this.offerClients[1].email
        }`;
      return `Et ${this.$t("folder.offerStatus.offerClients", {
        client: this.offerClients.length - 1,
      })}`;
    },
    isPublished() {
      if (!this.offer.publishDate) return false;
      return this.offer.publishDate.getTime() < new Date().getTime();
    },
  },
  methods: {
    changeExpandStatus() {
      this.$emit("expandChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_header {
  display: flex;
  cursor: pointer;
  .icon_left {
    width: 32px;
    background: #bda0ed;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is_spam {
      background: #ffc084;
    }
    &.chips_canceled {
      background: #eeeef7;
      color: #707080;
    }
    &.refused {
      background: #ff5267;
    }
    & > .icon {
      &::v-deep {
        .c_icon {
          width: auto;
          height: 14px;
          color: #fff;
        }
        .v-icon {
          width: 14px;
          height: 14px;
          color: #fff;
        }
      }
    }
  }
  .sections {
    padding: 16px;
    display: flex;
    align-items: center;
    flex: 1;
    .section_left {
      padding: 8px;
      padding-left: 16px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .section_info {
        font-family: "Inter";
        font-style: normal;
        flex: 1;
        .section_title {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #4f4f4f;
        }
        .section_content {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
        }
      }
    }
    .chips_container {
      display: flex;
      justify-content: center;
      flex: 1;
      .scheduled_chips {
        display: flex;
        align-items: center;
        padding: 8px;
        height: auto;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        flex: none;
        margin: auto;
        width: 281px;
        .chips_label {
          padding-left: 15px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #353542;
        }
      }
    }
    .section_center {
      padding: 8px;
      flex: 1;
      .section_title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #707080;
      }
      .section_content {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #353542;
        margin-top: 6px;
      }
      .section_footer {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #707080;
        margin-top: 6px;
        &.text_primary {
          color: #2536cc;
        }
      }
    }
    .section_right {
      width: 200px;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .chips {
        background: #eeeef7;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #707080;
        padding: 4px 8px;
        border-radius: 24px;
        &.chips_success {
          background: #f0ffd6;
          color: #18a300;
        }
        &.chips_danger {
          background: #fef9fa;
          color: #ff5267;
        }
      }
    }
  }
  .icon_right {
    flex: none;
    width: 76px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
